import { addresses, PRICE_KEY } from "./configs/constants";

export function getContractAddress(chainId, name) {
  if (!addresses[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!addresses[chainId][name]) {
    // throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
    console.error(`Unknown contract "${name}" for chainId ${chainId}`)
    return ""
  }
  return addresses[chainId][name];
}

export function getPriceKey(chainId, name) {
  if (!PRICE_KEY[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }
  if (!PRICE_KEY[chainId][name]) {
    // throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
    console.error(`Unknown contract "${name}" for chainId ${chainId}`)
    return ""
  }
  return PRICE_KEY[chainId][name];
}