import { getContractAddress } from "src/Addresses";
import { useWeb3Context } from "./web3Context";
import { useAppSelector } from "src/state/hooks";
import { useMemo } from "react";
import {
  usePositionReaderContract,
  useRouterContract,
  useVaultReaderContract,
  useVaultV2Contract,
} from "./useContract";
import { useSingleCallResult } from "src/lib/hooks/multicall";
import { useMultipleContractMultipleData } from "src/lib/hooks/useMultipleContractMultipleData";
import { Interface } from "@ethersproject/abi";
import { abi as PositionReaderABI } from "../abis/PositionReader.json";
import { abi as VaultReaderABI } from "../abis/VaultReader.json";
import { expandDecimals } from "src/helpers/Helpers";
import { ethers } from "ethers";

const POSITIONREADER_INTERFACE = new Interface(PositionReaderABI);
const VAULTTOKEN_INTERFACE = new Interface(VaultReaderABI);
const vaultPropsLength = 15;
export const useVaultTokenInfoV4 = (elpName?: string) => {
  const { chainID } = useWeb3Context();

  const nativeTokenAddress = getContractAddress(chainID, "NATIVE_TOKEN");
  const vaultReaderContract = useVaultReaderContract();
  const PositionReaderContract = usePositionReaderContract();

  const allToken = useAppSelector((state: any) => {
    return state.app.allToken || [];
  });
  const tokenMap = useAppSelector((state: any) => {
    return state.app.tokensMap || {};
  });
  const tokensArr = useAppSelector((state: any) => {
    return state.app.tokensArr || [];
  });
  const whitelistedTokenAddresses = allToken.map((item: any) => item.address);

  const { contract: contract1, prams: prams1 } = useMemo(() => {
    return tokensArr.reduce((pre: any, curr: any) => {
      if (!curr) return pre;
      const { vault_address, positionRouter_address } = curr;
      if (
        !vault_address ||
        !positionRouter_address ||
        !vaultReaderContract ||
        !curr
      )
        return pre;
      if (pre.contract) {
        pre.contract.push(vaultReaderContract.address);
        pre.prams.push([
          [
            vault_address,
            positionRouter_address,
            nativeTokenAddress,
            expandDecimals(1, 18),
            whitelistedTokenAddresses,
          ],
        ]);
      } else {
        pre = {
          contract: [vaultReaderContract.address],
          prams: [
            [
              [
                vault_address,
                positionRouter_address,
                nativeTokenAddress,
                expandDecimals(1, 18),
                whitelistedTokenAddresses,
              ],
            ],
          ],
        };
      }
      return pre;
    }, {});
  }, [
    tokensArr,
    vaultReaderContract,
    nativeTokenAddress,
    whitelistedTokenAddresses,
  ]);
  const tokenInfoV4s = useMultipleContractMultipleData(
    contract1 ? contract1 : [undefined],
    VAULTTOKEN_INTERFACE,
    "getVaultTokenInfoV4",
    prams1,
    undefined,
    6000000
  );
  const oldFilterData = useMemo(() => {
    return tokenInfoV4s.reduce((pre: any, curr: any) => {
      const { result } = curr[0];
      if (!result) return pre;
      const vaultTokenInfo = result[0];
      allToken.map((tokens: any, i: number) => {
        const token = { ...tokens };
        token.poolAmount = vaultTokenInfo[i * vaultPropsLength];
        token.reservedAmount = vaultTokenInfo[i * vaultPropsLength + 1];
        token.availableAmount = token.poolAmount.sub(token.reservedAmount);
        token.usdxAmount = vaultTokenInfo[i * vaultPropsLength + 2];
        token.redemptionAmount = vaultTokenInfo[i * vaultPropsLength + 3];
        token.weight = vaultTokenInfo[i * vaultPropsLength + 4];
        token.bufferAmount = vaultTokenInfo[i * vaultPropsLength + 5];
        token.maxUsdxAmount = vaultTokenInfo[i * vaultPropsLength + 6];
        token.globalShortSize = vaultTokenInfo[i * vaultPropsLength + 7];
        token.maxGlobalShortSize = vaultTokenInfo[i * vaultPropsLength + 8];
        token.maxGlobalLongSize = vaultTokenInfo[i * vaultPropsLength + 9];
        token.minPrice = vaultTokenInfo[i * vaultPropsLength + 10];
        token.maxPrice = vaultTokenInfo[i * vaultPropsLength + 11];
        token.guaranteedUsd = vaultTokenInfo[i * vaultPropsLength + 12];
        token.maxPrimaryPrice = vaultTokenInfo[i * vaultPropsLength + 13];
        token.minPrimaryPrice = vaultTokenInfo[i * vaultPropsLength + 14];
        const oldtoken = pre[token.address.toLowerCase()];
        if (oldtoken) {
          if (
            oldtoken.minPrice.lte(0) ||
            oldtoken.maxPrice.lte(0) ||
            oldtoken.maxPrimaryPrice.lte(0) ||
            oldtoken.minPrimaryPrice.lte(0)
          ) {
            pre[token.address.toLowerCase()] = token;
          }
        } else {
          pre[token.address.toLowerCase()] = token;
        }
      });
      return pre;
    }, {});
  }, [allToken, tokenInfoV4s]);
  return oldFilterData;
};

export const useTokenInfo = (elpName?: string) => {
  const positionReaderContract = usePositionReaderContract();
  const allToken = useAppSelector((state: any) => {
    return state.app.allToken || [];
  });
  const ELPMAP = useAppSelector((state: any) => {
    return state.app.ELPMAP || {};
  });
  const whitelistedTokenAddresses = allToken.map((item: any) => item.address);

  const { vaultAddress } = useMemo(() => {
    if (!elpName || !ELPMAP[elpName])
      return {
        vaultAddress: undefined,
      };

    return {
      vaultAddress: ELPMAP[elpName].vault_address,
    };
  }, [elpName, ELPMAP]);
  const prams = useMemo(() => {
    if (!vaultAddress || !whitelistedTokenAddresses) return undefined;
    return [vaultAddress, whitelistedTokenAddresses];
  }, [vaultAddress, whitelistedTokenAddresses]);
  const { result } = useSingleCallResult(
    positionReaderContract,
    "getTokenInfo",
    prams ?? [undefined],
    {
      gasRequired: 4000000,
    }
  );

  return useMemo(() => {
    if (!result) return;
    return result[0];
  }, [result]);
};

export const useTotalTokenWeights = (elpName: string) => {
  const ELPMAP = useAppSelector((state: any) => {
    return state.app.ELPMAP || {};
  });
  const { vaultAddress } = useMemo(() => {
    if (!elpName || !ELPMAP[elpName])
      return {
        vaultAddress: undefined,
      };

    return {
      vaultAddress: ELPMAP[elpName].vault_address,
    };
  }, [elpName, ELPMAP]);
  const vaultV2Contract = useVaultV2Contract(vaultAddress);
  const { result } = useSingleCallResult(vaultV2Contract, "totalTokenWeights");
  return useMemo(() => {
    if (!result) return;
    return result[0];
  }, [result]);
};

export const useQueryValidSwap = (
  amount: ethers.BigNumber | undefined,
  iswap: boolean,
  routerAddress: string,
  fromTokenAddress: string
) => {
  const routerContract = useRouterContract(routerAddress);

  // const pamrs = useMemo(() => {
  //   if (
  //     !iswap ||
  //     !routerContract ||
  //     !amount ||
  //     amount.lte(0) ||
  //     !fromTokenAddress
  //   )
  //     return [undefined];
  //   return [fromTokenAddress, amount.toString()];
  // }, [amount, fromTokenAddress, iswap, routerContract]);
  // useEffect(() => {
  //   if (!amount || !fromTokenAddress) return;
  //   routerContract?.swapMaxRatio(fromTokenAddress).then((r) => {
  //   });
  // }, [amount, fromTokenAddress, routerContract]);
  return 1;
};
