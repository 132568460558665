import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Messages from "./components/Messages/Messages";
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  DISABLE_ORDER_VALIDATION_KEY,
  IS_PNL_IN_LEVERAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  SLIPPAGE_BPS_KEY,
  useLocalStorageSerializeKey,
} from "./helpers/Helpers";
import useTheme from "./hooks/useTheme";
import { useWeb3Context } from "./hooks/web3Context";
import { storeQueryParameters } from "./helpers/QueryParameterHelper";
import { light as lightTheme } from "./themes/light";
import "./style.scss";
import LoadingSplash from "./components/LoadingSplash";
import { loadAccountDetails } from "./slices/AccountSlice";
import { loadAppDetails } from "./slices/AppSlice";
import WidgetBots from "src/components/WidgetBot";
import "./style.scss";
const Zoom = cssTransition({
  enter: "zoomIn",
  exit: "zoomOut",
  appendPosition: false,
  collapse: true,
  collapseDuration: 200,
  duration: 200,
});

import "./assets/css/App.css";
import "./assets/css/toast.scss";
import "./assets/css/AppOrder.css";
import "./assets/css/Input.css";
import "./assets/css/Shared.css";
import "./components/AddressDropdown/AddressDropdown.css";

const DEBUG = false;
import Header from "./components/Header";
const Dashboard = lazy(() => import("./views/dashboard"));
const Earn = lazy(() => import("./views/earn/index"));
const Auction = lazy(() => import("./views/Auction/index"));
const Governance = lazy(() => import("./views/governance"));
const Leaderboard = lazy(() => import("./views/Leaderboard"));
const ESBT = lazy(() => import("./views/ESBT"));
const EUSD = lazy(() => import("./views/EUSD"));
const DAO = lazy(() => import("./views/DAO"));
const Closed = lazy(() => import("./views/Closed"));

import { Trade } from "./views/trade";
// const blockExplorer = targetNetwork.blockExplorer;

import { Box } from "@material-ui/core";
import AOS from "aos";
import "aos/dist/aos.css";
import { toastError } from "./helpers/toastHelpers";
import { COMMUNITY_ROUTER_OBJ } from "./constants/misc";
import { useCheckVersion } from "src/hooks/useCheckVersion";
AOS.init({
  duration: 200,
  delay: 0,
});

function App() {
  useTheme();
  const location = useLocation();
  const { connect, connected, address, chainID, provider, hasCachedProvider } =
    useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);
  const dispatch = useDispatch();
  const [pathname, setPathname] = useState(location.pathname);
  const [isCommunity, setisCommunity] = useState(false);

  useEffect(() => {
    const isWhitelisted = COMMUNITY_ROUTER_OBJ.hasOwnProperty(pathname);
    setisCommunity(isWhitelisted);
  }, [pathname, COMMUNITY_ROUTER_OBJ]);

  async function loadDetails(whichDetails) {
    let loadProvider = provider;
    if (whichDetails === "app") {
      loadApp(loadProvider);
    }

    if (whichDetails === "account" && address && connected) {
      loadAccount(loadProvider);
    }
  }
  const loadApp = useCallback(
    (loadProvider) => {
      batch(() => {
        dispatch(
          loadAppDetails({
            networkID: chainID,
            provider: loadProvider,
            address,
            connected,
          })
        );
      });
    },
    [connected]
  );
  const loadAccount = useCallback(
    (loadProvider) => {
      batch(() => {
        dispatch(
          loadAccountDetails({
            networkID: chainID,
            address,
            provider: loadProvider,
          })
        );
      });
    },
    [connected]
  );
  useEffect(() => {
    // don't load ANY details until wallet is Checked
    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked]);

  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected) {
      loadDetails("account");
    }
  }, [connected]);

  // useEffect(() => {
  //   if (hasCachedProvider()) {
  //     // then user DOES have a wallet
  //     connect().then(() => {
  //       setWalletChecked(true);
  //     });
  //   } else {
  //     // then user DOES NOT have a wallet
  //     setWalletChecked(true);
  //   }

  //   // We want to ensure that we are storing the UTM parameters for later, even if the user follows links
  //   storeQueryParameters();
  // }, []);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  let themeMode = lightTheme;

  const pendingTransactions = useSelector((state) => {
    return state.pendingTransactions;
  });

  const [pendingTxns, setPendingTxns] = useState([]);
  const exchangeRef = useRef();
  const callExchangeRef = (method, ...args) => {
    if (!exchangeRef || !exchangeRef.current) {
      return;
    }
    exchangeRef.current[method](...args);
  };

  // handle the subscriptions here instead of within the Exchange component to avoid unsubscribing and re-subscribing
  // each time the Exchange components re-renders, which happens on every data update
  const onUpdatePosition = (...args) =>
    callExchangeRef("onUpdatePosition", ...args);
  const onClosePosition = (...args) =>
    callExchangeRef("onClosePosition", ...args);
  const onIncreasePosition = (...args) =>
    callExchangeRef("onIncreasePosition", ...args);
  const onDecreasePosition = (...args) =>
    callExchangeRef("onDecreasePosition", ...args);
  const onCancelIncreasePosition = (...args) =>
    callExchangeRef("onCancelIncreasePosition", ...args);
  const onCancelDecreasePosition = (...args) =>
    callExchangeRef("onCancelDecreasePosition", ...args);

  const [
    savedShouldDisableOrderValidation,
    setSavedShouldDisableOrderValidation,
  ] = useLocalStorageSerializeKey(
    [chainID, DISABLE_ORDER_VALIDATION_KEY],
    false
  );

  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] =
    useLocalStorageSerializeKey(
      [chainID, SHOULD_SHOW_POSITION_LINES_KEY],
      false
    );

  const [showPnlAfterFees, setShowPnlAfterFees] = useState(false);
  const [isPnlInLeverage, setIsPnlInLeverage] = useState(false);
  const [savedIsPnlInLeverage, setSavedIsPnlInLeverage] =
    useLocalStorageSerializeKey([chainID, IS_PNL_IN_LEVERAGE_KEY], false);
  const [savedShowPnlAfterFees, setSavedShowPnlAfterFees] =
    useLocalStorageSerializeKey([chainID, SHOW_PNL_AFTER_FEES_KEY], false);
  const [
    savedShouldDisableValidationForTesting,
    setSavedShouldDisableValidationForTesting,
  ] = useLocalStorageSerializeKey(
    [chainID, DISABLE_ORDER_VALIDATION_KEY],
    false
  );
  const [savedSlippageAmount, setSavedSlippageAmount] =
    useLocalStorageSerializeKey(
      [chainID, SLIPPAGE_BPS_KEY],
      DEFAULT_SLIPPAGE_AMOUNT
    );
  const [slippageAmount, setSlippageAmount] = useState(0.3);
  const onInputValueChange = (e) => {
    setSlippageAmount(e.target.value);
  };
  const [showSetting, setShowSetting] = useState(false);
  const openSettings = () => {
    const slippage = parseInt(savedSlippageAmount);
    setSlippageAmount((slippage / BASIS_POINTS_DIVISOR) * 100);
    setIsPnlInLeverage(savedIsPnlInLeverage);
    setShowPnlAfterFees(savedShowPnlAfterFees);
    setShowSetting(true);
  };
  const saveSettings = () => {
    const slippage = parseFloat(slippageAmount);
    if (isNaN(slippage)) {
      // dispatch(error(`Invalid slippage value`));
      toastError(`Invalid slippage value`);
      return;
    }
    if (slippage > 5) {
      // dispatch(error(`Slippage should be less than 5%`));
      toastError(`Slippage should be less than 5%`);
      return;
    }

    const basisPoints = (slippage * BASIS_POINTS_DIVISOR) / 100;
    if (parseInt(basisPoints) !== parseFloat(basisPoints)) {
      // dispatch(error(`Max slippage precision is 0.01%`));
      toastError(`Max slippage precision is 0.01%`);
      return;
    }

    setSavedIsPnlInLeverage(isPnlInLeverage);
    setSavedShowPnlAfterFees(showPnlAfterFees);
    setSavedSlippageAmount(basisPoints);
    setShowSetting(false);
  };

  // useEffect(() => {
  //   toastTransaction("Transaction Submitted", "", "123");
  //   setTimeout(() => toastTransaction2("Transaction Submitted", "", "456"), 3000);
  // }, []);

  useCheckVersion();

  return (
    <ThemeProvider theme={themeMode}>
      <Messages />
      <WidgetBots></WidgetBots>
      <ToastContainer
        limit={1}
        transition={Zoom}
        position="bottom-right"
        closeButton={false}
        newestOnTop={false}
        pauseOnFocusLoss
        closeOnClick={false}
        draggable={false}
        icon={false}
      />
      <CssBaseline />
      <Suspense fallback={<LoadingSplash></LoadingSplash>}>
        <Box
          display="flex"
          flexDirection="column"
          className={`app-container`}
          // className={`${pathname == "/ESBT" ? "esbt-container" : "app-container"}`}
        >
          {isCommunity ? (
            <div className="">
              <Switch>
                <Route exact path="/Leaderboard/BoardingBridge">
                  <Leaderboard />
                </Route>
                <Route exact path="/Leaderboard/HolyGrail">
                  <Leaderboard />
                </Route>
                <Route exact path="/Leaderboard/HolyGrail">
                  <Redirect to="/Leaderboard/HolyGrail" />
                </Route>
              </Switch>
            </div>
          ) : (
            <>
              {/* <Header
                slippageAmount={slippageAmount}
                onInputValueChange={onInputValueChange}
                saveSettings={saveSettings}
                showPnlAfterFees={showPnlAfterFees}
                setShowPnlAfterFees={setShowPnlAfterFees}
                isPnlInLeverage={isPnlInLeverage}
                setIsPnlInLeverage={setIsPnlInLeverage}
                openSettings={openSettings}
                showSetting={showSetting}
                setShowSetting={setShowSetting}
              /> */}
              <div className="flex-1">
                <Switch>
                  <Route exact path="/">
                    <Closed />
                    {/* <Dashboard
                      walletChecked={walletChecked}
                      savedSlippageAmount={savedSlippageAmount}
                      pendingTxns={pendingTxns}
                      setPendingTxns={setPendingTxns}
                    /> */}
                  </Route>
                  <Route exact path="/Earn">
                    <Earn
                      walletChecked={walletChecked}
                      savedSlippageAmount={savedSlippageAmount}
                      pendingTxns={pendingTxns}
                      setPendingTxns={setPendingTxns}
                    />
                  </Route>
                  <Route exact path="/Auction">
                    <Auction />
                  </Route>
                  <Route exact path="/Leaderboard">
                    <Leaderboard />
                  </Route>
                  <Route exact path="/ESBT">
                    <ESBT walletChecked={walletChecked} />
                  </Route>
                  <Route exact path="/DAO">
                    <DAO />
                  </Route>
                  <Route exact path="/Governance">
                    <Governance />
                  </Route>
                  <Route exact path="/EUSD">
                    <EUSD
                      walletChecked={walletChecked}
                      savedSlippageAmount={savedSlippageAmount}
                      pendingTxns={pendingTxns}
                      setPendingTxns={setPendingTxns}
                    />
                  </Route>
                  <Route exact path="/Trade">
                    <Trade
                      ref={exchangeRef}
                      savedShowPnlAfterFees={savedShowPnlAfterFees}
                      savedIsPnlInLeverage={savedIsPnlInLeverage}
                      setSavedIsPnlInLeverage={setSavedIsPnlInLeverage}
                      savedSlippageAmount={savedSlippageAmount}
                      setPendingTxns={setPendingTxns}
                      pendingTxns={pendingTxns}
                      savedShouldShowPositionLines={
                        savedShouldShowPositionLines
                      }
                      setSavedShouldShowPositionLines={
                        setSavedShouldShowPositionLines
                      }
                      savedShouldDisableOrderValidation={
                        savedShouldDisableOrderValidation
                      }
                    />
                  </Route>
                  <Route exact path="/">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              </div>
            </>
          )}
        </Box>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
