import useInterval from "src/hooks/useInterval";

import { useCallback, useMemo, useState } from "react";
import { useQueryEDETFee, useQueryPrice, useQueryPrice_gns } from "./hooks";
import { HTTP_POLL_DELAY, HTTP_QUEUEQUERY_DELAY } from "src/constants/misc";
import { useWeb3Context } from "src/hooks";

/**
 * 网络相关轮询
 * @returns
 */
export default function Updater(): null {
  const [, setTimes] = useState<number>(0);
  const queryPrice = useQueryPrice();
  const { address } = useWeb3Context();

  const queryPrice_gns = useQueryPrice_gns();
  const qeuryEdetFee = useQueryEDETFee();

  const queryQueue = useMemo((): any[] => {
    const queue = [];
    queue.push(queryPrice);
    queue.push(queryPrice_gns);
    queue.push(qeuryEdetFee);
    return queue;
  }, [qeuryEdetFee, queryPrice, queryPrice_gns]);

  const queryDataHandler = useCallback(() => {
    setTimes((prev: number) => {
      return ++prev;
    });

    const len = queryQueue.length;
    for (let i = 0; i < len; ++i) {
      if (!queryQueue || typeof queryQueue[i] != "function") continue;

      setTimeout(() => {
        queryQueue[i]();
      }, HTTP_QUEUEQUERY_DELAY * (i + 1));
    }
  }, [queryQueue]);

  useInterval(queryDataHandler, HTTP_POLL_DELAY, true);

  return null;
}
