import { ReactComponent as Bnb } from "src/assets/images/tokens/BNB.svg";
import { ReactComponent as Eth } from "src/assets/images/tokens/ETH.svg";
import { addresses as ADDRESS } from "../configs/constants";
import { SupportedChainId } from "./chainENV/chains.base";
//  ELP 默认查询  数据列表
type ListProps = {
  name: ELP_TYPES;
  top: string;
  Elp_address: string;
  ElpManager_address: string;
  rewardTracker_address: string;
  isOld?: boolean; // 是否是旧版elp
  vault_address: string;
  positionRouter_address: string;
  orderBook_address: string;
  router_address: string;
  positionManager_address: string;
  isGNS?: boolean; // 是否是gns 模式
  routerSign_adddress?: string; // 新版签名模式
  vaultUtil_addr?: string; // 新版查询
  isEdetReward?: boolean; // 是否有edet 奖励
};

type LpListProps = {
  name: string;
  stakeToken: string;
  isLp: boolean;
  pool: string;
  imgUrl: any;
  stablecoin: boolean;
  rewardToken: string;
  token0?: {
    address: string;
    decimals: number;
  };
  token1?: {
    address: string;
    decimals: number;
  };
};

export enum ELP_TYPES {
  ELP = "ELP-1P",
  ELP2 = "ELP-1",
  ELP3 = "ELP-3",
}
export const ELP_LIST: { [key: number]: ListProps[] } = {
  [SupportedChainId.BSCTEST]: [
    {
      name: ELP_TYPES.ELP,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCTEST].ELP_1p,
      ElpManager_address: ADDRESS[SupportedChainId.BSCTEST].ElpManager_ELP_1p,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCTEST].RewardTracker_ELP_1p,
      vault_address: ADDRESS[SupportedChainId.BSCTEST].Vault_ELP_1p,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionRouter_ELP_1p,
      orderBook_address: ADDRESS[SupportedChainId.BSCTEST].OrderBook_ELP_1p,
      router_address: ADDRESS[SupportedChainId.BSCTEST].Router_ELP_1p,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionManager_ELP_1p,
      routerSign_adddress: ADDRESS[SupportedChainId.BSCTEST].RouterSign_ELP_1p,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCTEST].VaultUtils_ELP_1p,
      isEdetReward: true,
    },
    {
      name: ELP_TYPES.ELP3,
      top: "Top 50 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCTEST].ELP_3,
      ElpManager_address: ADDRESS[SupportedChainId.BSCTEST].ElpManager_ELP_3,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCTEST].RewardTracker_ELP_3,
      vault_address: ADDRESS[SupportedChainId.BSCTEST].Vault_ELP_3,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionRouter_ELP_3,
      orderBook_address: ADDRESS[SupportedChainId.BSCTEST].OrderBook_ELP_3,
      router_address: ADDRESS[SupportedChainId.BSCTEST].Router_ELP_3,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCTEST].PositionManager_ELP_3,
      routerSign_adddress: ADDRESS[SupportedChainId.BSCTEST].RouterSign_ELP_3,
      isGNS: true,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCTEST].VaultUtils_ELP_3,
    },
  ],
  [SupportedChainId.BSCMAINNET]: [
    {
      name: ELP_TYPES.ELP,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP_1p,
      ElpManager_address:
        ADDRESS[SupportedChainId.BSCMAINNET].ElpManager_ELP_1p,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker_ELP_1p,
      vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault_ELP_1p,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter_ELP_1p,
      orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook_ELP_1p,
      router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router_ELP_1p,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionManager_ELP_1p,
      routerSign_adddress:
        ADDRESS[SupportedChainId.BSCMAINNET].RouterSign_ELP_1p,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCMAINNET].VaultUtils_ELP_1p,
      isEdetReward: true,
    },
    {
      name: ELP_TYPES.ELP3,
      top: "Stablecoin",
      Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP_3,
      ElpManager_address: ADDRESS[SupportedChainId.BSCMAINNET].ElpManager_ELP_3,
      rewardTracker_address:
        ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker_ELP_3,
      vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault_ELP_3,
      positionRouter_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter_ELP_3,
      orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook_ELP_3,
      router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router_ELP_3,
      positionManager_address:
        ADDRESS[SupportedChainId.BSCMAINNET].PositionManager_ELP_3,
      routerSign_adddress:
        ADDRESS[SupportedChainId.BSCMAINNET].RouterSign_ELP_3,
      isGNS: true,
      vaultUtil_addr: ADDRESS[SupportedChainId.BSCMAINNET].VaultUtils_ELP_3,
    },
    // {
    //   name: "ELP-1",
    //   top: "Top 5 ETF",
    //   Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP,
    //   ElpManager_address: ADDRESS[SupportedChainId.BSCMAINNET].ElpManager,
    //   rewardTracker_address: ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker,
    //   vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault,
    //   positionRouter_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter,
    //   orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook,
    //   router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router,
    //   positionManager_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionManager,
    // },
    // {
    //   name: "ELP-2",
    //   top: "Top 50 ETF",
    //   Elp_address: ADDRESS[SupportedChainId.BSCMAINNET].ELP_2,
    //   ElpManager_address: ADDRESS[SupportedChainId.BSCMAINNET].ElpManager_ELP_2,
    //   rewardTracker_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].RewardTracker_ELP_2,
    //   vault_address: ADDRESS[SupportedChainId.BSCMAINNET].Vault_ELP_2,
    //   positionRouter_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionRouter_ELP_2,
    //   orderBook_address: ADDRESS[SupportedChainId.BSCMAINNET].OrderBook_ELP_2,
    //   router_address: ADDRESS[SupportedChainId.BSCMAINNET].Router_ELP_2,
    //   positionManager_address:
    //     ADDRESS[SupportedChainId.BSCMAINNET].PositionManager_ELP_2,
    // },
  ],
  [SupportedChainId.ARB_TEST]: [
    {
      name: ELP_TYPES.ELP2,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.ARB_TEST].ELP,
      ElpManager_address: ADDRESS[SupportedChainId.ARB_TEST].ElpManager,
      rewardTracker_address: ADDRESS[SupportedChainId.ARB_TEST].RewardTracker,
      vault_address: ADDRESS[SupportedChainId.ARB_TEST].Vault,
      positionRouter_address: ADDRESS[SupportedChainId.ARB_TEST].PositionRouter,
      orderBook_address: ADDRESS[SupportedChainId.ARB_TEST].OrderBook,
      router_address: ADDRESS[SupportedChainId.ARB_TEST].Router,
      positionManager_address:
        ADDRESS[SupportedChainId.ARB_TEST].PositionManager,
      routerSign_adddress: ADDRESS[SupportedChainId.ARB_TEST].RouterSign_ELP,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARB_TEST].VaultUtils,
    },
  ],
  [SupportedChainId.ARBITRUM]: [
    {
      name: ELP_TYPES.ELP2,
      top: "Top 5 ETF",
      Elp_address: ADDRESS[SupportedChainId.ARBITRUM].ELP,
      ElpManager_address: ADDRESS[SupportedChainId.ARBITRUM].ElpManager,
      rewardTracker_address: ADDRESS[SupportedChainId.ARBITRUM].RewardTracker,
      vault_address: ADDRESS[SupportedChainId.ARBITRUM].Vault,
      positionRouter_address: ADDRESS[SupportedChainId.ARBITRUM].PositionRouter,
      orderBook_address: ADDRESS[SupportedChainId.ARBITRUM].OrderBook,
      router_address: ADDRESS[SupportedChainId.ARBITRUM].Router,
      positionManager_address:
        ADDRESS[SupportedChainId.ARBITRUM].PositionManager,
      routerSign_adddress: ADDRESS[SupportedChainId.ARBITRUM].RouterSign_ELP,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARBITRUM].VaultUtils,
      isEdetReward: true,
    },
    {
      name: ELP_TYPES.ELP3,
      top: "Stablecoin",
      Elp_address: ADDRESS[SupportedChainId.ARBITRUM].ELP_3,
      ElpManager_address: ADDRESS[SupportedChainId.ARBITRUM].ElpManager_ELP_3,
      rewardTracker_address:
        ADDRESS[SupportedChainId.ARBITRUM].RewardTracker_ELP_3,
      vault_address: ADDRESS[SupportedChainId.ARBITRUM].Vault_ELP_3,
      positionRouter_address:
        ADDRESS[SupportedChainId.ARBITRUM].PositionRouter_ELP_3,
      orderBook_address: ADDRESS[SupportedChainId.ARBITRUM].OrderBook_ELP_3,
      router_address: ADDRESS[SupportedChainId.ARBITRUM].Router_ELP_3,
      positionManager_address:
        ADDRESS[SupportedChainId.ARBITRUM].PositionManager_ELP_3,
      routerSign_adddress: ADDRESS[SupportedChainId.ARBITRUM].RouterSign_ELP_3,
      isGNS: true,
      vaultUtil_addr: ADDRESS[SupportedChainId.ARBITRUM].VaultUtils_ELP_3,
    },
  ],
};

export const STAKE_LP_LIST: { [key: number]: LpListProps[] } = {
  [SupportedChainId.BSCTEST]: [
    // {
    //   name: "ETH",
    //   stakeToken: "0x96562f0FB4f7a8e1F7F3fFa1A685C476BF4815b4",
    //   isLp: true,
    //   pool: "0xCAc54DB38Cd98A55498FaFB0269f8C3D24D85732",
    //   imgUrl: Eth,
    //   stablecoin: true,
    //   rewardToken: "0x126205981b5c95C5746F9Af451c644b8663EAd3f",
    // },
  ],
  [SupportedChainId.BSCMAINNET]: [
    {
      name: "BNB",
      stakeToken: "0xa8cd0cd8fb6277d97813a49baaf50106fe88b923",
      isLp: true,
      pool: "0xF0D95D61a73F0d4E990847c74ed7D896ff97c54C",
      imgUrl: Bnb,
      stablecoin: false,
      rewardToken: "0x43f649919f4ac48874d7f65d361702e4447dec0c",
      token0: {
        address: "0x4136129ac3ac90cf9817548b24d35e73e9457e05", // ede
        decimals: 18,
      },
      token1: {
        address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c", // wbnb
        decimals: 18,
      },
    },
  ],
  [SupportedChainId.ARBITRUM]: [
    {
      name: "ETH",
      stakeToken: "0x7ca686b3795784f12643127c5c3f56aa107a04c3",
      isLp: true,
      pool: "0xF8b84d6ba110cAbE63bC42Dc78198B1D4bc8c139",
      imgUrl: Eth,
      stablecoin: true,
      rewardToken: "0x5566d132324181427ed4f46989121030bc6689c7",
      token0: {
        address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1", // weth
        decimals: 18,
      },
      token1: {
        address: "0xCCD3891c1452b7CB0E4632774B9365DC4eE24f20", // ede
        decimals: 18,
      },
    },
  ],
  [SupportedChainId.ARB_TEST]: [],
};

export const PAIR_DETAILS: {
  [key: number]: {
    [lp: string]:
      | {
          token0: {
            address: string;
            decimals: number;
            name: string;
          };
          token1: {
            address: string;
            decimals: number;
            name: string;
          };
        }
      | undefined;
  };
} = {
  [SupportedChainId.BSCMAINNET]: {
    "0xa8cd0cd8fb6277d97813a49baaf50106fe88b923": {
      token0: {
        address: "0x4136129ac3ac90cf9817548b24d35e73e9457e05",
        decimals: 18,
        name: "ede",
      },
      token1: {
        address: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        decimals: 18,
        name: "WBNB",
      },
    },
  },
  [SupportedChainId.ARBITRUM]: {
    "0x7ca686b3795784f12643127c5c3f56aa107a04c3": {
      token0: {
        address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
        decimals: 18,
        name: "weth",
      },
      token1: {
        address: "0xccd3891c1452b7cb0e4632774b9365dc4ee24f20",
        decimals: 18,
        name: "ede",
      },
    },
  },
  [SupportedChainId.BSCTEST]: {},
  [SupportedChainId.ARB_TEST]: {},
};
