import { PRICE_API } from "src/constants/misc";
import httpClient from "./httpClient";
import axios from "axios";
interface HttpResponse {
  code: number;
  data: any;
}

export const getPrices = (): Promise<HttpResponse> => {
  return httpClient.get("/prices") as Promise<HttpResponse>;
};

export const getPrices_gns = (): Promise<HttpResponse> => {
  return httpClient.get("/gnsprices") as Promise<HttpResponse>;
};
export const getPythPrices = async (
  ids: string[],
  chainId: number
): Promise<any> => {
  // const chainIds = process.env.REACT_APP_NETWORKID as string;
  const baseUrl = PRICE_API[chainId];
  try {
    const data = await axios.get(baseUrl, {
      params: {
        ids: ids,
      },
    });
    const priceData = data.data.reduce((acc: any, id: any) => {
      const buffer = Buffer.from(id, "base64");
      const priceData = "0x" + buffer.toString("hex");
      acc.push(priceData);
      return acc;
    }, []);

    const value = ids.length;
    return { priceData, value };
  } catch (error) {
    console.log("[error]:", error);
  }
  return false;
};

export const getEDETFee = (
  roundId: number,
  address: string,
  chainID: number | string
): Promise<HttpResponse> => {
  const baseUrl = "https://data.ede.finance/elpStakeInfoTree";
  return axios.post(baseUrl, {
    roundId,
    address,
    chainID,
  }) as Promise<HttpResponse>;
};
