import { useDispatch } from "react-redux";
import { getEDETFee, getPrices, getPrices_gns } from "src/services/api";
import { useAppSelector } from "../hooks";
import { edetRewards, priceList, priceList_gns } from "./actions";
import { useWeb3Context } from "src/hooks";
import { getRoundId } from "src/hooks/useDataList";
interface Response {
  code: number | string;
  data: any;
}
export function useQueryPrice() {
  const dispatch = useDispatch();
  return () => {
    getPrices().then((res: Response) => {
      const data = res || {};
      dispatch(priceList(data));
    });
  };
}

export function usePrices() {
  return useAppSelector(({ http: { price } }) => price);
}

export function useQueryPrice_gns() {
  const dispatch = useDispatch();
  return () => {
    getPrices_gns().then((res: Response) => {
      const data = res || [];
      dispatch(priceList_gns(data));
    });
  };
}

export function useQueryEDETFee() {
  const dispatch = useDispatch();
  const { chainID, address } = useWeb3Context();
  const roundId = getRoundId();
  return function () {
    getEDETFee(roundId, address, chainID).then((res) => {
      if (res.data && res.data.code == 200 && res.data.data) {
        dispatch(edetRewards(res.data.data));
      }
    });
  };
}

export function useEdetRewards() {
  return useAppSelector(({ http: { elp1Edet } }) => elp1Edet);
}

export function usePrices_gns() {
  return useAppSelector(({ http: { price_gns } }) => price_gns);
}
